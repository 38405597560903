<template>
    <div class="data_pagination mt20">
        <div class="text">共 {{total}} 条记录，第 {{current}} / {{pages}} 页</div>
        <el-pagination background layout="prev, pager, next, sizes, jumper" :page-size="size" :total="total" :current-page="current" :page-sizes="[10, 20, 30]" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
    </div>
</template>

<script>
  export default {
    name: "pagination",
    props: {
      size: {
        type: Number,
        default: 0
      },
      total: {
        type: Number,
        default: 0
      },
      current: {
        type: Number,
        default: 0
      },
      pages: {
        type: Number,
        default: 0
      }
    },
    methods: {
      sizeChange (e) {
        this.$emit('pageChange', {current: this.current, size: e})
      },
      currentChange (e) {
        this.$emit('pageChange', {current: e, size: this.size})
      }
    }
  }
</script>
